import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";
import { PrismicRichText, SliceZone } from "@prismicio/react";
import { Container, Row, Col } from "react-bootstrap";
import { GatsbyImage } from "gatsby-plugin-image";
import Seo from "../components/meta/Seo";
import Layout from "../components/common/Layout";
import Anchor from "../components/elements/Anchor";
import "../styles/components/elements/page_banner.scss";
import "../styles/pages/blog_post.scss";
import components from "../components/slices/index";
import BlogPostRecommendations from "../components/elements/BlogPostRecommendations";
import { constructActiveDoc } from "../utilities/helpers";

import fbIcon from "../../static/images/icons/fb-blog.svg";
import linkedIcon from "../../static/images/icons/linkedIn-blog.svg";

const BlogPost = ({ data }) => {
  if (!data) return null;
  const fb = data.settings.data.social_media[0].link.url;
  const linkedIn = data.settings.data.social_media[1].link.url;

  const { page } = data;
  const pageClass = page.type;
  // console.log("pageClass", pageClass);

  return (
    <div style={{ backgroundColor: "#F6F8FC" }} className={`${page.type}-page`}>
      <Seo page={page} settings={data.settings} />
      <Layout
        className={`${data.page.type} ${data}`}
        activeDocMeta={constructActiveDoc(page)}
      >
        <Container
          fluid
          className={`${pageClass} page-banner max-container text-center p-md-0`}
          style={
            page.data.blog_image.gatsbyImageData
              ? {
                  backgroundImage: `url(${page.data.blog_image.url})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  borderRadius: "15px",
                }
              : {}
          }
        />
        <Container className="max-container blog-post-section">
          <Row className="justify-content-center">
            <Col
              md={5}
              className="d-flex justify-content-between justify-content-md-center"
            >
              <p className="post-tag me-md-5">{page.data.tag}</p>
              <p>{page.data.publication_date}</p>
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col md={11}>
              <h1 className="post-title text-center">{page.data.title.text}</h1>
            </Col>
          </Row>
          <Row className="social-media">
            <Col className="d-flex align-items-end justify-content-center my-3">
              <div className="social me-3">
                <Anchor href={fb} target="_blank">
                  <img src={fbIcon} alt="facebook icon" />
                </Anchor>
              </div>

              <div className="social">
                <Anchor href={linkedIn} target="_blank">
                  <img src={linkedIcon} alt="linked in icon" />
                </Anchor>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center mt-2 mb-5">
            <Col md={9}>
              <PrismicRichText field={data.page.data.text.richText} />
            </Col>
          </Row>
        </Container>
        <SliceZone components={components} slices={page.data.body} />
        <BlogPostRecommendations />
      </Layout>
    </div>
  );
};

export const query = graphql`
  query blogPostQuery($lang: String, $id: String) {
    settings: prismicSettings(lang: { eq: $lang }) {
      ...prismicSettingsFragment
    }
    page: prismicBlogPost(lang: { eq: $lang }, id: { eq: $id }) {
      ...prismicBlogPostFragment
    }
  }
`;

export default BlogPost;
